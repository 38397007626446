import { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { locationStates } from 'shared/config';
import { Login } from './login';
import { Password } from './password';
import { Registration } from './registration';

export const Router = () => {
  const searchParams = new URLSearchParams(locationStates).toString();

  return (
    <Suspense fallback={<div />}>
      <Routes>
        <Route path="registration" element={<Registration />} />
        <Route path="password/*" element={<Password />} />

        <Route index={true} element={<Login />} />

        {/* Additional redirects from fusionAuth default routes */}
        <Route
          path="/oauth2/register"
          element={
            <Navigate to={{ pathname: '/registration', search: searchParams }} replace={true} />
          }
        />

        <Route
          path="*"
          element={<Navigate to={{ pathname: '/', search: searchParams }} replace={true} />}
        />
      </Routes>
    </Suspense>
  );
};
