import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary, Provider } from '@rollbar/react';

import { App } from 'app/App';
import { LocalesProvider } from 'app/LocalesProvider';
import { rollbarConfig } from 'shared/config';
import { analyticsInit } from 'shared/lib';

analyticsInit();

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <Provider config={rollbarConfig}>
    <ErrorBoundary>
      <LocalesProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </LocalesProvider>
    </ErrorBoundary>
  </Provider>
);
