import styles from './reset_form.module.scss';

import { Formik, FormikHelpers } from 'formik';
import { Button, Input, Text, Title } from 'tp-design-system';

import { useTranslate } from 'shared/hooks';
import { validation } from 'shared/lib';
import { AlertMessage } from 'shared/ui';

export type Values = {
  email: string;
};

type Props = {
  onSubmit: (values: Values, formikHelpers: FormikHelpers<Values>) => void;
  alertMessage: string;
  loading: boolean;
};

const initialValues = {
  email: ''
};

export const ResetForm = ({ onSubmit, alertMessage, loading }: Props) => {
  const { t } = useTranslate();

  return (
    <Formik initialValues={initialValues} validate={validation} onSubmit={onSubmit}>
      {({ handleSubmit, values, errors, handleChange, submitCount }) => (
        <form onSubmit={handleSubmit} data-testid="reset-form" noValidate={true}>
          <Title size="300" weight="medium" as="h1">
            {t('reset_password_form.title')}
          </Title>

          <Text variant="text-300" className={styles.text}>
            {t('reset_password_form.message')}
          </Text>

          {!!alertMessage && <AlertMessage text={alertMessage} />}

          <Input
            name="email"
            type="email"
            label={t('auth_form.partner.email')}
            placeholder={t('auth_form.partner.email')}
            data-testid="email"
            value={values.email}
            onChange={handleChange}
            error={submitCount > 0 && errors.email ? t(errors.email) : undefined}
            size="md"
          />

          <Button
            size="xl"
            type="primary"
            loading={loading}
            data-testid="reset_password_form_submit"
            block={true}
            className={styles.button}
            htmlType="submit"
          >
            {t('reset_password_form.send')}
          </Button>
        </form>
      )}
    </Formik>
  );
};
