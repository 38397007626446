import qs from 'qs';

import { getCookie, setCookie } from 'shared/lib';
import { LocaleCode } from 'shared/types';

export const detectLocale = (): LocaleCode => {
  const matchedParams = qs.parse(window.location.search.slice(1));
  const qsLang = matchedParams.locale && String(matchedParams.locale);

  const cookieLang = getCookie('app_locale');

  const navigatorLang: string = window.navigator.languages
    ? window.navigator.languages[0]
    : window.navigator.language ||
      // Had to fix with any to find browserLanguage || userLanguage
      // because they can exists only in IE and not a part of the standards
      (window.navigator as any).browserLanguage ||
      (window.navigator as any).userLanguage;

  let code = (qsLang || cookieLang || navigatorLang).slice(0, 2).toLowerCase();

  if (!['ru', 'en'].includes(code)) {
    code = 'en';
  }

  if (!getCookie('app_locale')) {
    setCookie('app_locale', code, 30 * 12 * 50);
  }

  return code as LocaleCode;
};
