import styles from './change_password_form.module.scss';

import { useLocation } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import qs from 'qs';
import { Button, Input, Title } from 'tp-design-system';

import { useTranslate } from 'shared/hooks';
import {
  alphaNumericRegExp,
  minLengthRegExp,
  mixedCasesRegExp,
  specialCharsRegExp,
  validation
} from 'shared/lib';
import { AlertMessage } from 'shared/ui';

export type Values = {
  password: string;
};

type Props = {
  onSubmit: (values: Values, formikHelpers: FormikHelpers<Values>) => void;
  alertMessage: string;
  loading: boolean;
};

const initialValues = {
  password: ''
};

export const ChangePasswordForm = ({ onSubmit, alertMessage, loading }: Props) => {
  const { t } = useTranslate();
  const { search } = useLocation();
  const { state } = qs.parse(search, { ignoreQueryPrefix: true });

  const translateKey = state ? 'create_password' : 'change_password';

  return (
    <Formik initialValues={initialValues} validate={validation} onSubmit={onSubmit}>
      {({ handleSubmit, values, errors, handleChange, submitCount }) => (
        <form onSubmit={handleSubmit} data-testid="change_password-form" noValidate={true}>
          <Title size="300" weight="medium" as="h1" className={styles.header}>
            {t(`${translateKey}.title`)}
          </Title>

          {!!alertMessage && <AlertMessage text={alertMessage} />}

          <Input.Password
            name="password"
            autoComplete="new-password"
            label={t('auth_form.new_password')}
            placeholder={t('auth_form.enter_new_password')}
            data-testid="password"
            value={values.password}
            onChange={handleChange}
            error={submitCount > 0 && errors.password ? t(errors.password) : undefined}
            size="md"
            rulesTitle={t('password_rules.title')}
            rules={[
              {
                regex: minLengthRegExp,
                text: t('password_rules.rule1'),
                invalid:
                  submitCount > 0 && !!errors.password && !minLengthRegExp.test(values.password)
              },
              {
                regex: alphaNumericRegExp,
                text: t('password_rules.rule2'),
                invalid:
                  submitCount > 0 && !!errors.password && !alphaNumericRegExp.test(values.password)
              },
              {
                regex: mixedCasesRegExp,
                text: t('password_rules.rule3'),
                invalid:
                  submitCount > 0 && !!errors.password && !mixedCasesRegExp.test(values.password)
              },
              {
                regex: specialCharsRegExp,
                text: t('password_rules.rule4'),
                invalid:
                  submitCount > 0 && !!errors.password && !specialCharsRegExp.test(values.password)
              }
            ]}
          />

          <Button
            size="xl"
            type="primary"
            loading={loading}
            data-testid="change_password_submit"
            className={styles.submit}
            block={true}
            htmlType="submit"
          >
            {t(`${translateKey}.send`)}
          </Button>
        </form>
      )}
    </Formik>
  );
};
