import styles from './reset_success.module.scss';

import { Link } from 'react-router-dom';
import qs from 'qs';
import { Button, Roundel, Svg, Text } from 'tp-design-system';

import { locationStates } from 'shared/config';
import { useTranslate } from 'shared/hooks';

export const ResetSuccess = () => {
  const { t } = useTranslate();

  return (
    <div data-testid="password_sent" className={styles.passwordSent}>
      <Roundel
        type="success"
        size="md"
        icon={<Svg.Check24 />}
        className={styles.icon}
        loadingAnimation={true}
      />

      <Text
        variant="text-300"
        weight="semiBold"
        className={styles.text}
        dangerouslySetInnerHTML={{ __html: t('auth_password_sent.text') }}
      />

      <Text
        variant="text-200"
        data-testid="password_sent-footer"
        className={styles.footer}
        dangerouslySetInnerHTML={{ __html: t('auth_password_sent.note') }}
      />

      <Button
        as={Link}
        to={{ pathname: '/', search: qs.stringify(locationStates) }}
        size="xl"
        type="primary"
        data-testid="reset_success_button"
        block={true}
        className={styles.button}
      >
        {t('auth_password_sent.button')}
      </Button>
    </div>
  );
};
