import { useState } from 'react';

import { DataLayer } from 'shared/api';
import { detectLocale, getCookie, objectReducer, removeCookie, setCookie } from 'shared/lib';
import { LocalesContext } from 'shared/model';
import { IncomeTranslation, LocaleCode, LocaleObject } from 'shared/types';

type Props = {
  children: React.ReactNode;
};

export const LocalesProvider = ({ children }: Props) => {
  const [ruData, setRuData] = useState<LocaleObject>({});
  const [enData, setEnData] = useState<LocaleObject>({});
  const [locales, setLocales] = useState<LocaleCode[]>([]);
  const [language, setLanguage] = useState<LocaleCode>(detectLocale());

  const setLocale = (code: LocaleCode, overrideCookie = true) => {
    if (overrideCookie) {
      removeCookie('app_locale');
      setCookie('app_locale', code, 30 * 12 * 50);
    }

    setLanguage(code);
  };

  const getData = async (lang: LocaleCode) => {
    const response = await fetch(`/app_locales/${lang}/translation.json?v=${new Date().getTime()}`);
    const json: IncomeTranslation = await response.json();

    if (lang === 'ru') {
      setRuData(objectReducer(json));
    } else {
      setEnData(objectReducer(json));
    }
  };

  const getLocales = (locales: LocaleCode[]) => {
    return Promise.all(locales.map(code => getData(code)));
  };

  const setup = async () => {
    try {
      const locales: LocaleCode[] = window.location.search.includes('_analytics_debug=1')
        ? ['ru', 'en']
        : await DataLayer.getJSON<LocaleCode[]>('/xapi/locales');

      await getLocales(locales);

      if (!locales.includes(language)) {
        setLocale('en', !['ru', 'en'].includes(getCookie('app_locale') || ''));
      }

      setLocales(locales);
    } catch (e) {
      setLocale('en');
      window.Rollbar?.error(e);
    }
  };

  const getPhrase = (key: string): string | number | string[] | undefined => {
    const currentPhrases = language === 'ru' ? ruData : enData;
    return currentPhrases && currentPhrases[key];
  };

  const t = (key: string): string => {
    const phrase = getPhrase(key) || key;

    if (typeof phrase === 'object') {
      return phrase.join(',');
    }

    return `${phrase}`;
  };

  const tArray = (key: string): string[] => {
    const phrase = getPhrase(key);

    if (typeof phrase === 'object') {
      return phrase;
    } else {
      return [];
    }
  };

  const exists = (key: string): boolean => {
    return !!getPhrase(key);
  };

  const changeLanguage = (lang: LocaleCode): void => {
    removeCookie('app_locale');
    setCookie('app_locale', lang, 30 * 12 * 50);

    document.documentElement.lang = lang;
    setLocale(lang);
  };

  return (
    <LocalesContext.Provider
      value={{
        setup,
        language,
        locales,
        t,
        tArray,
        exists,
        changeLanguage
      }}
    >
      {children}
    </LocalesContext.Provider>
  );
};
