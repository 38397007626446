import './styles/app.scss';

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useRealVhUnits, useTranslate } from 'shared/hooks';
import { analyticsEvent } from 'shared/lib';
import { AppLayout } from './ui/app_layout/AppLayout';

export const App = () => {
  const [loading, setLoading] = useState(true);
  const { setup, language } = useTranslate();
  const { pathname, search } = useLocation();

  useRealVhUnits();

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    analyticsEvent({
      type: 'ga',
      category: 'pageview',
      action: `${document.location.protocol}//${document.location.hostname}${pathname}${search}`
    });

    analyticsEvent({
      type: 'sp',
      category: 'pageview',
      action: 'page_view'
    });
  }, [pathname, search]);

  const init = async () => {
    try {
      await setup();
    } catch (err) {
      window.Rollbar?.error(err);
    } finally {
      document.documentElement.lang = language;
      setLoading(false);
    }
  };

  return <AppLayout loading={loading} />;
};
