import styles from './login_footer.module.scss';

import { Link } from 'react-router-dom';
import qs from 'qs';
import { Text } from 'tp-design-system';

import { locationStates } from 'shared/config';
import { useTranslate } from 'shared/hooks';

export const LoginFooter = () => {
  const { t } = useTranslate();

  return (
    <Text variant="text-200" className={styles.footer}>
      {t('auth_form.no_account')}{' '}
      <Link
        data-testid="registration-link"
        to={{ pathname: '/registration', search: qs.stringify(locationStates) }}
        className={styles.link}
      >
        {t('auth_form.registration')}
      </Link>
    </Text>
  );
};
