import styles from './promo.module.scss';

import { Text, Title } from 'tp-design-system';

import { isBrand } from 'shared/config';
import { useTranslate } from 'shared/hooks';
import { classNames } from 'shared/lib';

import programsBrandsEn from '../assets/programs-brands-en.png';
import programsBrandsRu from '../assets/programs-brands-ru.png';
import programsPartnersEn from '../assets/programs-partners-en.svg';
import programsPartnersRu from '../assets/programs-partners-ru.svg';

const programImages = {
  partners: {
    en: programsPartnersEn,
    ru: programsPartnersRu
  },
  brands: {
    en: programsBrandsEn,
    ru: programsBrandsRu
  }
};

export const BackgroundPromo = () => {
  const { t, language } = useTranslate();

  return (
    <div className={classNames([styles.background, styles[language], isBrand && styles.brand])}>
      <div className={styles.text}>
        <Title
          size="500"
          weight="bold"
          className={styles.title}
          dangerouslySetInnerHTML={{
            __html: isBrand ? t('promo.title.brands') : t('promo.title.partners')
          }}
        />

        <img
          src={programImages[isBrand ? 'brands' : 'partners'][language]}
          alt=""
          height={80}
          className={styles.programs}
        />

        <Text variant="text-500">
          {isBrand ? t('promo.subtitle.brands') : t('promo.subtitle.partners')}
        </Text>
      </div>
    </div>
  );
};
