type Params = {
  focused: boolean;
  submitCount: number;
  isNotBusinessEmail: boolean;
  error: string | undefined;
};

export const getBrandEmailErrorKey = ({
  focused,
  submitCount,
  isNotBusinessEmail,
  error
}: Params): string | undefined => {
  if (isNotBusinessEmail) {
    return !focused ? error : undefined;
  }

  return submitCount > 0 ? error : undefined;
};
