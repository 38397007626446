import styles from './change_password_success.module.scss';

import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { Button, Title } from 'tp-design-system';

import { locationStates } from 'shared/config';
import { useTranslate } from 'shared/hooks';

export const ChangePasswordSuccess = () => {
  const { t } = useTranslate();
  const { search } = useLocation();
  const { state } = qs.parse(search, { ignoreQueryPrefix: true });

  const translateKey = state ? 'create_password' : 'change_password';

  return (
    <div data-testid="password_changed">
      <div className={styles.text}>
        <Title size="300" weight="medium" as="h1">
          {t(`${translateKey}.success`)}
        </Title>
      </div>

      <Button
        type="link"
        as="a"
        size="xl"
        href={`/?${qs.stringify(locationStates)}`}
        data-testid="password_changed-link"
        block={true}
      >
        {t('change_password.success_link')}
      </Button>
    </div>
  );
};
