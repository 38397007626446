import { useEffect, useState } from 'react';
import { FormikErrors, FormikHelpers } from 'formik';

import { AuthResponse } from 'shared/api';
import { locationStates } from 'shared/config';
import { useTranslate } from 'shared/hooks';
import { ResetPasswordApi } from './api/ResetPasswordApi';
import { ResetFooter } from './ui/footer/ResetFooter';
import { ResetForm, Values } from './ui/form/ResetForm';
import { ResetSuccess } from './ui/success_placeholder/ResetSuccess';

export const PasswordForgot = () => {
  const { t, language } = useTranslate();
  const [alertMessage, setAlertMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.document.title = t('auth_form.meta_titles.password_recovery');
  }, []);

  const onSubmit = async (values: Values, formikHelpers: FormikHelpers<Values>) => {
    try {
      const { client_id, response_type, redirect_uri, ...restStates } = locationStates;

      setAlertMessage('');
      setLoading(true);

      const params = {
        client_id,
        response_type,
        redirect_uri,
        locale: language,
        email: values.email,
        state: restStates
      };

      await ResetPasswordApi.reset(params);

      setSuccess(true);
    } catch (e: any) {
      if (e.status === 400) {
        const response: AuthResponse = await e.json();

        if (response.errors?.length) {
          const errors: FormikErrors<Values> = {};

          response.errors.forEach(({ code }) => {
            if ([1, 3, 9].includes(code)) {
              errors.email = t(`errors.${code}`);
            } else {
              setAlertMessage(t(`errors.${code}`) || t('errors.500'));
            }
          });

          formikHelpers.setErrors(errors);
        }
      } else {
        setAlertMessage(`${e.status}: ${e.error}`);
        window.Rollbar?.error(e);
      }

      setLoading(false);
    }
  };

  if (success) {
    return <ResetSuccess />;
  }

  return (
    <>
      <ResetForm alertMessage={alertMessage} loading={loading} onSubmit={onSubmit} />

      <ResetFooter />
    </>
  );
};
