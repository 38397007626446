import TagManager from 'react-gtm-module';
import {
  newTracker,
  SelfDescribingJson,
  trackPageView,
  trackStructEvent
} from '@snowplow/browser-tracker';

import { isDev } from '../config';
import { isBrand, locationStates } from '../config/locationStates';

type AnalyticsProps = {
  type: 'ga' | 'sp';
  category: string;
  action: string;
  label?: string;
  property?: string;
  value?: string;
  context?: Record<string, string>;
};

type GtmProps = AnalyticsProps & {
  type: 'ga';
  property?: undefined;
  context?: undefined;
};

type SpProps = AnalyticsProps & {
  type: 'sp';
  label?: undefined;
  property?: undefined;
  value?: undefined;
};

type Analytics = GtmProps | SpProps;

const isDebug = window.location.search.includes('_analytics_debug=1');

export const isAnalyticsEnabled = (): boolean => {
  return !isDev || isDebug;
};

export const analyticsInit = (): void => {
  if (!isAnalyticsEnabled()) return;

  TagManager.initialize({
    gtmId: 'GTM-T7QG7KB'
  });

  window.dataLayer = window.dataLayer || [];

  newTracker(
    'sp',
    window.location.hostname !== 'passport.travelpayouts.com' ? 'dev.avsplow.com' : 'avsplow.com',
    {
      appId: isBrand ? 'travelpayouts_brands' : 'travelpayouts.com',
      /**
       * Tracker automatically discovers and sets the `configCookieDomain` value to the root domain.
       * https://docs.snowplow.io/docs/collecting-data/collecting-from-own-applications/javascript-trackers/browser-tracker/browser-tracker-v3-reference/tracker-setup/initialization-options/#automatically-discover-and-set-the-root-domain
       */
      discoverRootDomain: true,
      encodeBase64: false,
      postPath: '/a/j',
      /**
       * https://docs.snowplow.io/docs/collecting-data/collecting-from-own-applications/javascript-trackers/javascript-tracker/cookies-local-storage/how-the-tracker-stores-state/
       * https://gdpr.eu/
       */
      respectDoNotTrack: false
    }
  );
};

export const analyticsEvent = ({
  type,
  category,
  action,
  label,
  property,
  value,
  context = {}
}: Analytics): void => {
  if (!isAnalyticsEnabled()) return;

  switch (type) {
    // Google Analytics Events
    case 'ga':
      if (category === 'pageview') {
        window.dataLayer.push({
          event: 'Pageview',
          pagePath: document.location.pathname + document.location.search,
          pageTitle: document.title,
          originalLocation: action
        });
      } else {
        window.dataLayer.push({
          event: 'GAEvent',
          eventCategory: category,
          eventAction: action,
          eventLabel: label,
          eventValue: value
        });
      }

      break;

    // SnowPlow events
    case 'sp':
      const contextJson: SelfDescribingJson = {
        schema: 'event',
        data: {
          attr_parameters: locationStates,
          page_url: window.location.href,
          referer: document.referrer,
          viewport_size: {
            width: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
            height: Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
          },
          ...context
        }
      };

      if (category === 'pageview') {
        trackPageView({
          context: [contextJson]
        });
      } else {
        trackStructEvent({
          category,
          action,
          label,
          property,
          value,
          context: [contextJson]
        });
      }

      break;
  }
};
