import styles from './alert_message.module.scss';

import { Text } from 'tp-design-system';

import { useTranslate } from 'shared/hooks';
import { classNames } from 'shared/lib';

type Props = {
  text: string;
  isPositive?: boolean;
};

export const AlertMessage = ({ text, isPositive }: Props) => {
  const { t } = useTranslate();

  return (
    <Text
      variant="text-200"
      className={classNames([styles.alert, isPositive && styles.alertPositive])}
      data-testid="alert_message"
    >
      {t(text)}
    </Text>
  );
};
