import styles from './login_form.module.scss';

import { Link } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import qs from 'qs';
import { Button, Input, Text, Title } from 'tp-design-system';

import { isBrand, locationStates } from 'shared/config';
import { useTranslate } from 'shared/hooks';
import { validationLogin } from 'shared/lib';
import { AlertMessage } from 'shared/ui';

export type Values = {
  email: string;
  password: string;
  'g-recaptcha-response': string;
};

type Props = {
  onSubmit: (values: Values, formikHelpers: FormikHelpers<Values>) => void;
  loading: boolean;
  alertMessage: string;
};

const initialValues = {
  email: '',
  password: '',
  'g-recaptcha-response': ''
};

export const LoginForm = ({ onSubmit, loading, alertMessage }: Props) => {
  const { t } = useTranslate();
  const emailLabel = t(`auth_form.${isBrand ? 'brand' : 'partner'}.email`);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validate={validationLogin}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, values, errors, handleChange, submitCount }) => {
        return (
          <form
            onSubmit={handleSubmit}
            data-testid="login"
            noValidate={true}
            className={styles.form}
          >
            <Title size="300" weight="medium" as="h1" className={styles.title}>
              {t(`auth_form.${isBrand ? 'brand' : 'partner'}.login_title`)}
            </Title>

            {!!alertMessage && <AlertMessage text={alertMessage} />}

            <Input
              name="email"
              type="email"
              label={emailLabel}
              placeholder={emailLabel}
              autoComplete="username"
              data-testid="email"
              value={values.email}
              onChange={handleChange}
              error={submitCount > 0 && errors.email ? t(errors.email) : undefined}
              size="md"
            />

            <Input.Password
              name="password"
              label={t('auth_form.password')}
              className={styles.password}
              placeholder={t('auth_form.password')}
              data-testid="password"
              value={values.password}
              onChange={handleChange}
              error={submitCount > 0 && errors.password ? t(errors.password) : undefined}
              size="md"
              autoComplete="current-password"
            />

            <Text
              variant="text-100"
              as={Link}
              to={{ pathname: '/password/forgot', search: qs.stringify(locationStates) }}
              data-testid="forgot-link"
              className={styles.forgotLink}
            >
              {t('auth_form.forgot_password')}
            </Text>

            <Button
              size="xl"
              type="primary"
              loading={loading}
              data-testid="auth_form_submit"
              block={true}
              className={styles.formButton}
              htmlType="submit"
            >
              {t(`auth_form.${isBrand ? 'brand' : 'partner'}.login`)}
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};
