import { Navigate, Route, Routes } from 'react-router-dom';

import { locationStates } from 'shared/config';
import { PasswordChange } from './change';
import { PasswordForgot } from './forgot';

export const Router = () => {
  const searchParams = new URLSearchParams(locationStates);

  return (
    <Routes>
      <Route path="forgot" element={<PasswordForgot />} />
      <Route path="change/:change_password_id" element={<PasswordChange />} />

      <Route
        path="*"
        element={
          <Navigate
            to={{ pathname: '/password/forgot', search: searchParams.toString() }}
            replace={true}
          />
        }
      />
    </Routes>
  );
};
