import styles from './registration_footer.module.scss';

import { Link } from 'react-router-dom';
import qs from 'qs';
import { Text } from 'tp-design-system';

import { isBrand, locationStates } from 'shared/config';
import { useTranslate } from 'shared/hooks';
import { classNames } from 'shared/lib';

export const RegistrationFooter = () => {
  const { t } = useTranslate();

  return (
    <Text variant="text-200" className={classNames([styles.footer, isBrand && styles.brand])}>
      {t('auth_form.already_registered')}
      &nbsp;
      <Link
        data-testid="login-link"
        className={styles.link}
        to={{ pathname: '/', search: qs.stringify(locationStates) }}
      >
        {t(`auth_form.${isBrand ? 'brand' : 'partner'}.login`)}
      </Link>
    </Text>
  );
};
