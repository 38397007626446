import { useCallback, useEffect } from 'react';

/**
  Хук создает css переменную --vh, которая хранит в себе реальное значение с учетом
  мобильных тулбаров
  @example height: calc(var(--vh, 1vh) * 100);
*/

export const useRealVhUnits = () => {
  const setVar = useCallback(() => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, [window.innerHeight]);

  useEffect(() => {
    setVar();

    window.addEventListener('resize', setVar);

    return () => {
      window.removeEventListener('resize', setVar);
    };
  }, []);
};
