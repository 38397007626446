import styles from './reset_footer.module.scss';

import { Link } from 'react-router-dom';
import qs from 'qs';
import { Text } from 'tp-design-system';

import { locationStates } from 'shared/config';
import { useTranslate } from 'shared/hooks';

export const ResetFooter = () => {
  const { t } = useTranslate();

  return (
    <Text variant="text-200" className={styles.footer}>
      {t('reset_password_form.remember_this')}{' '}
      <Link
        data-testid="login-link"
        className={styles.link}
        to={{ pathname: '/', search: qs.stringify(locationStates) }}
      >
        {t('reset_password_form.login')}
      </Link>
    </Text>
  );
};
