import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormikErrors, FormikHelpers } from 'formik';

import { AuthResponse } from 'shared/api';
import { locationStates } from 'shared/config';
import { useTranslate } from 'shared/hooks';
import { ChangePasswordApi } from './api/ChangePasswordApi';
import { ChangePasswordForm, Values } from './ui/form/ChangePasswordForm';
import { ChangePasswordSuccess } from './ui/success_placeholder/ChangePasswordSuccess';

export const PasswordChange = () => {
  const { t, language } = useTranslate();
  const change_password_id = useParams().change_password_id;
  const [alertMessage, setAlertMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.document.title = t('auth_form.meta_titles.password_change');
  }, []);

  const handleSubmit = async (values: Values, formikHelpers: FormikHelpers<Values>) => {
    if (!change_password_id) {
      return;
    }

    try {
      const { client_id, response_type, redirect_uri, ...restStates } = locationStates;

      setAlertMessage('');
      setLoading(true);

      const params = {
        client_id,
        response_type,
        redirect_uri,
        locale: language,
        change_password_id,
        password: values.password,
        password_confirm: values.password,
        state: restStates
      };

      await ChangePasswordApi.change(params);

      setSuccess(true);
    } catch (error: any) {
      if (error.status === 400) {
        const response: AuthResponse = await error.json();

        if (response.errors?.length) {
          const errors: FormikErrors<Values> = {};

          response.errors.forEach(({ code }) => {
            if ([2, 4, 10].includes(code)) {
              errors.password = t(`errors.${code}`);
            } else {
              setAlertMessage(t(`errors.${code}`) || t('errors.500'));
            }
          });

          formikHelpers.setErrors(errors);
        }
      } else {
        setAlertMessage(`${error.status}: ${error.error}`);
        window.Rollbar?.error(error);
      }

      setLoading(false);
    }
  };

  if (success) {
    return <ChangePasswordSuccess />;
  }

  return (
    <ChangePasswordForm loading={loading} alertMessage={alertMessage} onSubmit={handleSubmit} />
  );
};
