import styles from './brand_email_field.module.scss';

import { useState } from 'react';
import { FormikHandlers } from 'formik';
import { Input, Tooltip } from 'tp-design-system';

import { useTranslate } from 'shared/hooks';

import { getBrandEmailErrorKey } from '../../lib/getBrandEmailErrorKey';

type Props = {
  label: string;
  value: string;
  error: string | undefined;
  submitCount: number;
  onChange: FormikHandlers['handleChange'];
};

export const BrandEmailInput = ({ label, value, error, submitCount, onChange }: Props) => {
  const { t } = useTranslate();
  const [focused, setFocused] = useState(false);

  const isNotBusinessEmail = error === 'errors.invalid_brand_email';
  const errorKey = getBrandEmailErrorKey({ focused, submitCount, isNotBusinessEmail, error });
  const errorText = errorKey ? t(errorKey) : undefined;

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  return (
    <Tooltip visible={true} placement="bottom" text={errorText}>
      <div className={styles.inputWrapper}>
        <Input
          name="email"
          type="email"
          autoComplete="email"
          label={label}
          placeholder={label}
          data-testid="email"
          value={value}
          onChange={onChange}
          error={errorText}
          size="md"
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </div>
    </Tooltip>
  );
};
