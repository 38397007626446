import { useEffect, useState } from 'react';
import { FormikErrors, FormikHelpers } from 'formik';

import { AuthResponse } from 'shared/api';
import { locationStates } from 'shared/config';
import { useTranslate } from 'shared/hooks';
import { analyticsEvent, checkCaptcha, getFingerprint } from 'shared/lib';
import { LoginApi } from './api/LoginApi';
import { LoginFooter } from './ui/footer/LoginFooter';
import { LoginForm, Values } from './ui/form/LoginForm';

export const Login = () => {
  const { t, language } = useTranslate();
  const [alertMessage, setAlertMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.document.title = t('auth_form.meta_titles.sign_in');
  }, []);

  const onSubmit = async (values: Values, formikHelpers: FormikHelpers<Values>) => {
    try {
      const { client_id, response_type, redirect_uri, ...restStates } = locationStates;
      setAlertMessage('');
      setLoading(true);

      const [recaptcha, fingerprint] = await Promise.all([checkCaptcha(), getFingerprint()]);

      const params = {
        client_id,
        response_type,
        redirect_uri,
        locale: language,
        'g-recaptcha-response': recaptcha,
        user: {
          email: values.email,
          password: values.password
        },
        state: {
          ...restStates,
          fingerprint
        }
      };

      const response = await LoginApi.login(params);

      if (response.redirect_uri) {
        analyticsEvent({
          type: 'sp',
          category: 'passport',
          action: 'sign_in',
          context: {
            login: values.email
          }
        });

        window.location.href = response.redirect_uri;
      }
    } catch (e: any) {
      if (e instanceof Response && e.status === 400) {
        const response: AuthResponse = await e.json();

        if (response.errors?.length) {
          const errors: FormikErrors<Values> = {};

          response.errors.forEach(({ code }) => {
            if ([1, 3, 9].includes(code)) {
              errors.email = t(`errors.${code}`);
            } else if ([2, 4, 10].includes(code)) {
              errors.password = t(`errors.${code}`);
            } else {
              setAlertMessage(t(`errors.${code}`) || t('errors.500'));
            }
          });

          formikHelpers.setErrors(errors);
        }
      } else {
        setAlertMessage(typeof e === 'string' ? t(e) : `${e.status}: ${e.error}`);
        window.Rollbar?.error(e);
      }

      setLoading(false);
    }
  };

  return (
    <>
      <LoginForm alertMessage={alertMessage} loading={loading} onSubmit={onSubmit} />

      <LoginFooter />
    </>
  );
};
