import { IncomeTranslation, LocaleObject } from 'shared/types';

export const objectReducer = (obj: IncomeTranslation): LocaleObject => {
  const result: LocaleObject = {};

  const rec = (hash: IncomeTranslation, prefix: string): void => {
    for (const key in hash) {
      const name = `${prefix}${key}`;

      if (typeof hash[key] !== 'object' || Array.isArray(hash[key])) {
        result[name] = hash[key] as string | string[];
      } else {
        rec(hash[key] as IncomeTranslation, `${name}.`);
      }
    }
  };

  rec(obj, '');

  return result;
};
