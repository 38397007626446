import { useMemo } from 'react';
import { Button } from 'tp-design-system';

import { useTranslate } from 'shared/hooks';
import { LocaleCode } from 'shared/types';
import flagRu from './assets/flag_ru.svg';
import flagEn from './assets/flag_us.svg';

const Locales: Record<LocaleCode, string> = { ru: 'Русский', en: 'English' };

type LocaleItem = {
  code: LocaleCode;
  name: string;
};

export const LocaleSwitcher = () => {
  const { changeLanguage, language, t, locales } = useTranslate();

  const target = useMemo((): LocaleItem => {
    const code = locales.find(code => code !== language) || locales[0];

    return {
      code,
      name: Locales[code]
    };
  }, [language]);

  const handleClick = () => {
    changeLanguage(target.code);
  };

  if (locales.length <= 1) {
    return null;
  }

  return (
    <Button
      size="xl"
      type="link-gray"
      iconTrailing={<img src={target.code === 'en' ? flagEn : flagRu} alt="" />}
      onClick={handleClick}
      data-testid="locale_switcher"
      htmlType="button"
    >
      {t('switch_locale')} {target.name}
    </Button>
  );
};
