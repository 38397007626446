import styles from './app_layout.module.scss';

import { Loader, Text } from 'tp-design-system';

import { Router } from 'pages';
import { isBrand } from 'shared/config';
import { classNames } from 'shared/lib';

import LogoBrands from '../assets/logo_brands.svg?react';
import LogoPartners from '../assets/logo_partners.svg?react';
import { LocaleSwitcher } from '../LocaleSwitcher';
import { BackgroundPromo } from '../promo/BackgroundPromo';

type Props = {
  loading: boolean;
};

export const AppLayout = ({ loading }: Props) => {
  if (loading) {
    return (
      <div className={classNames([styles.wrapper, styles.loaderWrapper])}>
        <Loader className={styles.loader} size="2xl" />
      </div>
    );
  }

  return (
    <div className={styles.wrapper} data-testid="app">
      <div className={styles.content}>
        <div className={styles.header}>
          <a href="https://www.travelpayouts.com/">{isBrand ? <LogoBrands /> : <LogoPartners />}</a>
        </div>

        <div className={styles.main}>
          <Router />
        </div>

        <div className={styles.footer}>
          <LocaleSwitcher />

          <Text variant="text-300" className={styles.footerCopy}>
            &copy; Travelpayouts, 2011&ndash;
            <span data-testid="year-to">{new Date().getFullYear()}</span>
          </Text>
        </div>
      </div>

      <BackgroundPromo />
    </div>
  );
};
