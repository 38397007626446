import styles from './registration_form.module.scss';

import { useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import qs from 'qs';
import { Button, Input, Svg, Text, Title, Tooltip } from 'tp-design-system';

import { isBrand, locationStates, partnerClientId } from 'shared/config';
import { useTranslate } from 'shared/hooks';
import {
  alphaNumericRegExp,
  classNames,
  maxLengthRegExp,
  minLengthRegExp,
  mixedCasesRegExp,
  specialCharsRegExp,
  validation
} from 'shared/lib';
import { AlertMessage } from 'shared/ui';

import { BrandEmailInput } from '../brand_email_field/BrandEmailInput';

export type Values = {
  email: string;
  password: string;
  promotion_code: string;
};

type Props = {
  onSubmit: (values: Values, formikHelpers: FormikHelpers<Values>) => void;
  alertMessage: string;
  loading: boolean;
};

const initialValues = {
  email: locationStates.email || '',
  password: '',
  promotion_code: locationStates.promotion_code || ''
};

const PASSWORD_MAX_LENGTH = 64;

export const RegistrationForm = ({ loading, alertMessage, onSubmit }: Props) => {
  const { t, exists, tArray } = useTranslate();
  const [maxLengthError, setMaxLengthError] = useState(false);
  const [showPromo, setShowPromo] = useState(!!initialValues.promotion_code);

  const emailLabel = t(`auth_form.${isBrand ? 'brand' : 'partner'}.email`);

  const handleSubmit = (values: Values, formikHelpers: FormikHelpers<Values>) => {
    if (maxLengthError) return;

    onSubmit(values, formikHelpers);
  };

  return (
    <Formik initialValues={initialValues} validate={validation} onSubmit={handleSubmit}>
      {({ values, handleChange, handleSubmit, errors, submitCount }) => {
        const passwordChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
          handleChange(e);
          setMaxLengthError(e.currentTarget.value.length > PASSWORD_MAX_LENGTH);
        };

        return (
          <form
            onSubmit={handleSubmit}
            data-testid="registration"
            noValidate={true}
            className={styles.form}
          >
            <Title
              size="300"
              weight="medium"
              as="h1"
              className={classNames([styles.title, isBrand && styles.titleBrand])}
            >
              {t('auth_form.registration_title')}
            </Title>

            {isBrand && (
              <Text variant="text-200" className={styles.brandDescription}>
                {t('auth_form.brand.description.text')}{' '}
                <Tooltip
                  title={t('auth_form.registration_rules_list.title')}
                  extraNode={
                    <div className={styles.styledContent}>
                      {exists('auth_form.registration_rules_list.subtitle') && (
                        <Text
                          as="p"
                          variant="text-100"
                          dangerouslySetInnerHTML={{
                            __html: t('auth_form.registration_rules_list.subtitle')
                          }}
                        />
                      )}

                      <ul>
                        {tArray('auth_form.registration_rules_list.rules').map(el => (
                          <Text
                            as="li"
                            variant="text-100"
                            key={el}
                            dangerouslySetInnerHTML={{
                              __html: el
                            }}
                          />
                        ))}
                      </ul>

                      <Text
                        as="p"
                        variant="text-100"
                        dangerouslySetInnerHTML={{
                          __html: t('auth_form.registration_rules_list.comment.text').replace(
                            '%link%',
                            `/registration?${qs.stringify({
                              ...locationStates,
                              client_id: partnerClientId
                            })}`
                          )
                        }}
                      />
                    </div>
                  }
                  placement="right"
                >
                  <Svg.HelpCircle width={16} height={16} />
                </Tooltip>
              </Text>
            )}

            {!!alertMessage && <AlertMessage text={alertMessage} />}

            {isBrand ? (
              <BrandEmailInput
                label={emailLabel}
                value={values.email}
                error={errors.email}
                submitCount={submitCount}
                onChange={handleChange}
              />
            ) : (
              <Input
                name="email"
                type="email"
                autoComplete="email"
                label={emailLabel}
                placeholder={emailLabel}
                data-testid="email"
                value={values.email}
                onChange={handleChange}
                error={submitCount > 0 && errors.email ? t(errors.email) : undefined}
                size="md"
              />
            )}

            <Input.Password
              name="password"
              autoComplete="new-password"
              label={t('auth_form.password')}
              placeholder={t('auth_form.enter_password')}
              data-testid="password"
              value={values.password}
              onChange={passwordChange}
              error={submitCount > 0 && errors.password ? t(errors.password) : undefined}
              size="md"
              rulesTitle={t('password_rules.title')}
              className={classNames([styles.password, maxLengthError && styles.maxLength])}
              rules={[
                {
                  regex: maxLengthRegExp,
                  text: t('password_rules.rule5'),
                  invalid:
                    submitCount > 0 && !!errors.password && !maxLengthRegExp.test(values.password)
                },
                {
                  regex: minLengthRegExp,
                  text: t('password_rules.rule1'),
                  invalid:
                    submitCount > 0 && !!errors.password && !minLengthRegExp.test(values.password)
                },
                {
                  regex: alphaNumericRegExp,
                  text: t('password_rules.rule2'),
                  invalid:
                    submitCount > 0 &&
                    !!errors.password &&
                    !alphaNumericRegExp.test(values.password)
                },
                {
                  regex: mixedCasesRegExp,
                  text: t('password_rules.rule3'),
                  invalid:
                    submitCount > 0 && !!errors.password && !mixedCasesRegExp.test(values.password)
                },
                {
                  regex: specialCharsRegExp,
                  text: t('password_rules.rule4'),
                  invalid:
                    submitCount > 0 &&
                    !!errors.password &&
                    !specialCharsRegExp.test(values.password)
                }
              ]}
            />

            {!isBrand &&
              (showPromo ? (
                <Input
                  name="promotion_code"
                  label={t('auth_form.promotion_code')}
                  placeholder={t('auth_form.promotion_code')}
                  data-testid="promotion-code"
                  value={values.promotion_code}
                  onChange={handleChange}
                  error={
                    submitCount > 0 && errors.promotion_code ? t(errors.promotion_code) : undefined
                  }
                  size="md"
                  className={styles.promoCode}
                />
              ) : (
                <Button
                  type="link"
                  iconLeading={<Svg.Ticket01 />}
                  onClick={() => setShowPromo(true)}
                  className={styles.promo}
                  data-testid="promotion-link"
                  block={true}
                >
                  {t('auth_form.enter_promotion_code')}
                </Button>
              ))}

            <Button
              size="xl"
              type="primary"
              loading={loading}
              data-testid="registration_form_submit"
              block={true}
              className={styles.submit}
              htmlType="submit"
            >
              {t('auth_form.create_account')}
            </Button>

            <Text
              variant="text-200"
              className={styles.agreement}
              data-testid="registration_agreement"
              dangerouslySetInnerHTML={{
                __html: t(`auth_form.${isBrand ? 'brand' : 'partner'}.rules`)
              }}
            />
          </form>
        );
      }}
    </Formik>
  );
};
