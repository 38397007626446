import { HttpMethod, Payload } from './data_layer.types';

class DataLayer {
  private host = import.meta.env.DEV ? 'http://passport.dev.k8s.int.avs.io' : '';

  private getUrl(method: HttpMethod, url: string, body: Payload): string {
    if (method === 'GET' && body) {
      let params: string[] = [];

      Object.keys(body).forEach(key => {
        const value = body?.[key];
        if (value) {
          params.push(`${key}=${value}`);
        }
      });

      if (params.length) {
        return `${this.host}${url}?${params.join('&')}`;
      }
    }

    return `${this.host}${url}`;
  }

  private getParams(method: HttpMethod, body: Payload): RequestInit {
    const headers: HeadersInit = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    };

    const params: RequestInit = {
      method: method,
      mode: 'cors',
      credentials: 'same-origin',
      headers
    };

    if (method === 'POST') {
      return {
        ...params,
        body: JSON.stringify(body) ?? null
      };
    }

    return params;
  }

  private async fetchJSON<T>(method: HttpMethod, path: string, body: Payload = null): Promise<T> {
    const url = this.getUrl(method, path, body);
    const params = this.getParams(method, body);

    try {
      const response = await fetch(url, params);
      if (!response.ok && response.status >= 400) {
        throw response;
      }
      return await response.json();
    } catch (e) {
      throw e;
    }
  }

  getJSON<T>(url: string, body?: Payload): Promise<T> {
    return this.fetchJSON<T>('GET', url, body);
  }

  postJSON<T>(url: string, body: Payload): Promise<T> {
    return this.fetchJSON<T>('POST', url, body);
  }
}

const dataLayer = new DataLayer();

export default dataLayer;
