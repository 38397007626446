import { createContext } from 'react';

import { detectLocale } from 'shared/lib';
import { LocalesModel } from 'shared/types';

export const LocalesContext = createContext<LocalesModel>({
  language: detectLocale(),
  setup: async () => {},
  locales: [],
  t: (key: string) => key,
  tArray: (key: string) => [key],
  exists: (key: string) => !!key,
  changeLanguage: () => {}
});
