import { isBrand } from '../config/locationStates';

const brandsEmailsBlacklist = [
  'gmail.com',
  'yahoo.com',
  'hotmail.com',
  'aol.com',
  'hotmail.co.uk',
  'hotmail.fr',
  'msn.com',
  'yahoo.fr',
  'wanadoo.fr',
  'orange.fr',
  'comcast.net',
  'yahoo.co.uk',
  'yahoo.com.br',
  'yahoo.co.in',
  'live.com',
  'rediffmail.com',
  'free.fr',
  'gmx.de',
  'web.de',
  'yandex.ru',
  'ymail.com',
  'libero.it',
  'outlook.com',
  'uol.com.br',
  'bol.com.br',
  'mail.ru',
  'cox.net',
  'hotmail.it',
  'sbcglobal.net',
  'sfr.fr',
  'live.fr',
  'verizon.net',
  'live.co.uk',
  'googlemail.com',
  'yahoo.es',
  'ig.com.br',
  'live.nl',
  'bigpond.com',
  'terra.com.br',
  'yahoo.it',
  'neuf.fr',
  'yahoo.de',
  'alice.it',
  'rocketmail.com',
  'att.net',
  'laposte.net',
  'facebook.com',
  'bellsouth.net',
  'yahoo.in',
  'hotmail.es',
  'charter.net',
  'yahoo.ca',
  'yahoo.com.au',
  'rambler.ru',
  'hotmail.de',
  'tiscali.it',
  'shaw.ca',
  'yahoo.co.jp',
  'sky.com',
  'earthlink.net',
  'optonline.net',
  'freenet.de',
  't-online.de',
  'aliceadsl.fr',
  'virgilio.it',
  'home.nl',
  'qq.com',
  'telenet.be',
  'me.com',
  'yahoo.com.ar',
  'tiscali.co.uk',
  'yahoo.com.mx',
  'voila.fr',
  'gmx.net',
  'mail.com',
  'planet.nl',
  'tin.it',
  'live.it',
  'ntlworld.com',
  'arcor.de',
  'yahoo.co.id',
  'frontiernet.net',
  'hetnet.nl',
  'live.com.au',
  'yahoo.com.sg',
  'zonnet.nl',
  'club-internet.fr',
  'juno.com',
  'optusnet.com.au',
  'blueyonder.co.uk',
  'bluewin.ch',
  'skynet.be',
  'sympatico.ca',
  'windstream.net',
  'mac.com',
  'centurytel.net',
  'chello.nl',
  'live.ca',
  'aim.com',
  'bigpond.net.au'
];

type FormValues = {
  [key: string]: string | boolean;
};

// eslint-disable-next-line no-useless-escape
const emailRegExp: RegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const minLengthRegExp: RegExp = /^.{8,}$/;
export const maxLengthRegExp: RegExp = /^.{0,64}$/;
export const alphaNumericRegExp: RegExp = /^(?=.*[a-z])(?=.*[0-9])/i;
export const mixedCasesRegExp: RegExp = /(?=.*[a-z])(?=.*[A-Z])/;
// eslint-disable-next-line no-useless-escape
export const specialCharsRegExp: RegExp = /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/;

function validateBrandEmail(email: string): boolean {
  return !brandsEmailsBlacklist.some(blacklistEmail => email.endsWith(`@${blacklistEmail}`));
}

export function validation(values: FormValues): FormValues {
  const errors: FormValues = {};

  Object.keys(values).forEach(key => {
    const value = values[key];

    switch (key) {
      case 'email':
        if (!value) {
          errors[key] = 'errors.required_field';
        } else if (typeof value === 'string' && !emailRegExp.test(value)) {
          errors[key] = 'errors.invalid_email';
        } else if (isBrand && typeof value === 'string' && !validateBrandEmail(value)) {
          errors[key] = 'errors.invalid_brand_email';
        }

        break;

      case 'password':
        const matchRegEx =
          typeof value === 'string' &&
          minLengthRegExp.test(value) &&
          alphaNumericRegExp.test(value) &&
          mixedCasesRegExp.test(value) &&
          specialCharsRegExp.test(value);

        if (!value) {
          errors[key] = 'errors.required_field';
        } else if (!matchRegEx) {
          errors[key] = 'errors.password_too_short';
        }

        break;
    }
  });

  return errors;
}

export function validationLogin(values: FormValues): FormValues {
  const errors: FormValues = {};

  Object.keys(values).forEach(key => {
    const value = values[key];

    switch (key) {
      case 'email':
        if (!value) {
          errors[key] = 'errors.required_field';
        } else if (typeof value === 'string' && !emailRegExp.test(value)) {
          errors[key] = 'errors.invalid_email';
        }

        break;

      case 'password':
        if (!value) {
          errors[key] = 'errors.required_field';
        }

        break;
    }
  });

  return errors;
}
