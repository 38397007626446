const getDomainFromHost = (host: string = window.location.hostname) => {
  const hostNameArray = host.split('.');
  if (hostNameArray.length > 1) {
    return hostNameArray.slice(-2).join('.');
  }
  return host;
};

export const setCookie = (
  name: string,
  value: string,
  days: number,
  domain: string = getDomainFromHost()
) => {
  let expires = '';

  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }

  document.cookie = `${name}=${value || ''}${expires}; path=/; domain=${domain}`;
};

export const getCookie = (name: string) => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const removeCookie = (name: string, domain: string = getDomainFromHost()) => {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; domain=${domain}`;
};
