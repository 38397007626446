import { Configuration } from 'rollbar';

export const rollbarConfig: Configuration = {
  accessToken: '0c344db9c1c14ada8cabf2d61adab60d',
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: window.location.hostname !== 'passport.travelpayouts.com',
  payload: {
    environment: 'production',
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: import.meta.env.VITE_GIT_SHA
      }
    }
  }
};
