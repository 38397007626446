import qs from 'qs';

import { getCookie, setCookie } from '../lib/cookies';

type States = {
  client_id: string;
  response_type: string;
  redirect_uri: string;
  [key: string]: string;
};

const brandClientId = '660cbefc-b9f1-4315-a7fc-9e67bf9a2fdf';

export const partnerClientId = 'b0e02fcc-0ab4-4b2c-a164-742762783a4e';

const { client_id, response_type, redirect_uri, ...restSearchParams } = qs.parse(
  window.location.search,
  { ignoreQueryPrefix: true }
);

export const locationStates: States = {
  client_id: client_id ? String(client_id) : '',
  response_type: response_type ? String(response_type) : '',
  redirect_uri: redirect_uri ? decodeURI(String(redirect_uri)) : ''
};

// For local development only
if (import.meta.env.DEV) {
  if (!locationStates.client_id) {
    locationStates.client_id = partnerClientId;
  }

  if (!locationStates.response_type) {
    locationStates.response_type = 'code';
  }

  if (!locationStates.redirect_uri) {
    locationStates.redirect_uri = 'https://travelpayouts.dev.aviasales.ru/api/auth/callback';
  }
}

// Redirect to Homepage if required params not found
if (!locationStates.client_id || !locationStates.response_type || !locationStates.redirect_uri) {
  window.location.href = 'https://www.travelpayouts.com/';
}

Object.keys(restSearchParams).forEach(key => {
  const value = decodeURI(String(restSearchParams[key]));
  locationStates[key] = value;
});

const cookiesToCheck = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  'marker',
  'promotion_code',
  'ad_source',
  'ad_content',
  'tp_referrer',
  'regpage',
  '_gaexp'
];

cookiesToCheck.forEach(cookieName => {
  let value = restSearchParams[cookieName] || getCookie(cookieName);

  if (value) {
    if (value instanceof Array && typeof value[0] === 'string') {
      value = value.join(',');
    } else {
      value = String(value);
    }

    if (cookieName === 'marker') {
      locationStates.parent_marker = value;
    } else {
      locationStates[cookieName] = value;
    }

    if (!getCookie(cookieName)) {
      setCookie(cookieName, value, 30);
    }
  }
});

export const isBrand = locationStates.client_id === brandClientId;
