import { useEffect, useState } from 'react';
import { FormikErrors, FormikHelpers } from 'formik';

import { AuthResponse } from 'shared/api';
import { isBrand, locationStates } from 'shared/config';
import { useTranslate } from 'shared/hooks';
import { analyticsEvent, getFingerprint, setCookie } from 'shared/lib';
import { RegistrationApi } from './api/RegistrationApi';
import { RegistrationFooter } from './ui/footer/RegistrationFooter';
import { RegistrationForm, Values } from './ui/form/RegistrationForm';

export const Registration = () => {
  const { t, language } = useTranslate();
  const [alertMessage, setAlertMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.document.title = t('auth_form.meta_titles.sign_up');
  }, []);

  const onSubmit = async (values: Values, formikHelpers: FormikHelpers<Values>) => {
    try {
      const { client_id, response_type, redirect_uri, ...restStates } = locationStates;

      setAlertMessage('');
      setLoading(true);

      const fingerprint = await getFingerprint();

      const params = {
        client_id,
        response_type,
        redirect_uri,
        locale: language,
        user: {
          email: values.email,
          password: values.password
        },
        state: {
          ...restStates,
          promotion_code: values.promotion_code,
          referrer: document.referrer,
          registration_device_width: window.innerWidth,
          registration_device_height: window.innerHeight,
          fingerprint
        }
      };

      // remember the promocode in case a user just entered a new one into the input
      setCookie('promotion_code', values.promotion_code, 30);

      const response = await RegistrationApi.registration(params);

      if (response.redirect_uri) {
        setCookie('just_registered', '1', 7);

        analyticsEvent({
          type: 'ga',
          category: isBrand ? 'Brand Promo' : 'Account',
          action: isBrand ? 'Application sent' : 'Registration',
          label: 'Main'
        });

        analyticsEvent({
          type: 'sp',
          category: 'passport',
          action: 'register',
          context: {
            login: values.email
          }
        });

        window.location.href = response.redirect_uri;
      }
    } catch (e: any) {
      if (e.status === 400) {
        const response: AuthResponse = await e.json();

        if (response.errors?.length) {
          const errors: FormikErrors<Values> = {};

          response.errors.forEach(({ code }) => {
            if ([1, 3, 9, 19].includes(code)) {
              errors.email = t(`errors.${code}`);
            } else if ([2, 4, 10].includes(code)) {
              errors.password = t(`errors.${code}`);
            } else if (code === 18) {
              errors.promotion_code = t(`errors.${code}`);
            } else {
              setAlertMessage(t(`errors.${code}`) || t('errors.500'));
            }
          });

          formikHelpers.setErrors(errors);
        }
      } else {
        setAlertMessage(`${e.status}: ${e.error}`);
        window.Rollbar?.error(e);
      }

      setLoading(false);
    }
  };

  return (
    <>
      <RegistrationForm loading={loading} alertMessage={alertMessage} onSubmit={onSubmit} />

      <RegistrationFooter />
    </>
  );
};
