import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';

import { isDev } from '../config';

export const getFingerprint = async () => {
  return new Promise<string>(async resolve => {
    if (isDev) {
      resolve('');
    }

    try {
      const fp = await FingerprintJS.load({
        apiKey: 'CCxUDnEnchCzlCmUVp0b',
        endpoint: 'https://fp.travelpayouts.com'
      });
      const result = await fp.get();

      resolve(result?.visitorId || '');
    } catch (err) {
      // резолвим пустую строку, если не удалось получить fingerprint
      resolve('');
    }
  });
};
